<script>
import AuditoriumForm from "@/views/menu/auditorium/auditoriumForm";
import Component, { mixins } from "vue-class-component";
import { Utility } from "@/lib/Utility";

@Component({
  inject: {
    auditoriumService: "auditoriumService",
  },
})
export default class AuditoriumDetail extends mixins(AuditoriumForm) {
  formId = "detail-auditorium-form";

  get decoratedElements() {
    return [
      ...[
        {
          id: "entityLink",
          label: this.translations.labels.common_form_entity_link,
          type: "text",
          readonly: true,
          copyToClipboard: true,
        },
        {
          id: "agendaLink",
          label: this.translations.labels.common_form_agenda_link,
          type: "text",
          readonly: true,
          copyToClipboard: true,
        }
      ],
      ...this.allReadonlyFields
    ];
  }

  get fieldColumns() {
    return 2;
  }

  getFormTitle() {
    return this.translations.pageTitles.auditoriums_detail;
  }

  async afterCreate() {
    this.model = await this.auditoriumService.read(this.$route.params.id);
    this.model.entityLink = "Not yet generated";
    this.isReady = true;

    this.model.entityLink = Utility.generateEntityLink(this?.event, "Auditorium", this.model.id);
    this.model.agendaLink = Utility.generateEntityLink(this?.event, "agenda", null, { parent: this.model.id });
  }
}
</script>